@import '../../design/base';

.tds {
  .os-device-banner {
    display: none;
  }

  .unset-styles {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  .unset-styles:focus {
    outline: none;
  }

  .custom-header {
    height: 100px;
    grid-template-columns: repeat(2, 1fr) !important;

    .locale-selector, .locale-selector--choices a:hover, .locale-selector--choices .active {
      color: var(--white) !important;
      cursor: pointer;
    }

    .locale-selector--choices {
      color: var(--gray-3) !important;
      cursor: pointer;

      a {
        color: var(--gray-4) !important;
      }
    }

    .locale-selector {
      z-index: 1;
    }

    .open-menu {
      @include breakpoint-down-s {
        display: none !important;
      }
    }

    #account-menu {
      z-index: 10;

      .tds-dropdown-toggle i, .account-menu--logged-out {
        @include breakpoint-down-s {
          color: var(--white);
        }
      }
    }
  }

  .step-pagination {
    width: .6rem;
    height: .6rem;
    margin: .2rem;
    border-radius: 50%;
    border: 1px solid var(--black);
  }

  a {
    text-decoration: none;
  }

  #authModalContent {
    padding: 0;
    max-width: 800px;
    height: 40rem;
    border-radius: 1rem;

    @include breakpoint-up-s {
      width: 800px;
    }

    .modal-body {
      padding: 0;

      .bg-image-newsletter-survey {
        background-image: url('https://cdn.tag-walk.com/custom,fit_cover,width_1300/modal-auth-form-inscription-mode-newsletter-survey-description-bg.png');
      }

      .left-block {
        border-top-left-radius: .6rem;
        border-bottom-left-radius: .6rem;
        padding: 0 calc(var(--space-default) - 1px);

        @include breakpoint-up-s {
          width: 50%;
          height: 100%;
        }

        @include breakpoint-down-s {
          height: 30%;
          border-top-right-radius: .6rem;
          border-bottom-left-radius: 0;
        }
      }

      .right-block {
        overflow-y: auto;
        height: 40rem;
        max-height: 40rem;
        padding: calc(var(--space-unit) * 6) calc(var(--space-default) - 1px) 0;

        @include breakpoint-down-m {
          border-top-left-radius: .6rem;
          border-top-right-radius: .6rem;
          position: relative;
          bottom: 1rem;
          background-color: var(--white);
          padding-left: 0;
          padding-right: 0;
        }

        @include breakpoint-up-s {
          width: 55%;
        }
      }
    }
  }

  .action-title {
    font-size: 1.7rem;
  }

  .auth-body {
    height: 100%;

    @include text-body-medium;
    @include breakpoint-up-s {
      display: flex;
    }

    .success-block {
      text-align: center;

      @include breakpoint-up-s {
        width: 67%;
        margin: auto;
      }

      .description {
        p {
          display: inline;
        }
      }

      a {
        margin: var(--space-l) auto calc(var(--space-xl) + 8px);
        display: block;
        width: fit-content;
        padding-bottom: calc(var(--space-unit) - 1px);
      }

      .logo {
        padding: 0 calc(var(--space-default) - 1px);
        margin: var(--space-l) auto 56px;

        img {
          margin: 0 auto;
          display: block;
          width: 30px;
        }
      }

      .btn {
        display: block;
        margin: var(--space-l) auto;
      }
    }

    .icon-list {
      text-transform: uppercase;
      color: var(--white);
      margin-bottom: var(--space-default);

      span {
        margin-right: var(--space-unit);
      }

      p {
        font-size: calc(var(--text-base-size) * 1.125);
        color: var(--white);
        text-transform: uppercase;
      }
    }

    .title {
      white-space: break-spaces;
      font-size: calc(var(--text-base-size) * 1.3);
      line-height: 1.2;
      margin: var(--space-default) 0;
      text-align: center;
      font-weight: 400;
    }

    .custom-title {
      @include text-body-large;
      text-align: center;
      font-weight: 400;
      margin-bottom: var(--space-default);
    }

    .bg-black {
      color: var(--white);
      background-color: var(--black);
    }

    .bg-black:hover {
      color: var(--white);
      background-color: var(--black);
    }

    .logo {
      margin: var(--space-default) 0;

      img {
        width: 10%;
      }
    }

    button {
      padding-bottom: calc(var(--space-unit) - 1px);
    }

    button, a, input,
    #gender .css-1s2u09g-control,
    #jobTitle .css-1s2u09g-control,
    #country .css-1s2u09g-control,
    .css-1pahdxg-control,
    .alert-error {
      border-radius: 24px !important;
    }

    .toggle-switch {
      .form-check {
        display: flex;
        align-items: center;
        padding: 0;
        width: 100%;
        margin: var(--space-xs) 0 var(--space-xs);

        @include breakpoint-up-s {
          margin-top: var(--space-l);
        }
      }

      span {
        width: 80%;
        padding: 0 calc(var(--space-default) - 1px) 0 var(--space-default);
      }

      .form-check-label {
        width: 2.3rem;
        height: 1.3rem;
        background-color: var(--gray-5);
        border-radius: 1rem;
        position: relative;
        cursor: pointer;
        transition: background-color .2s;
      }

      .form-check-label:before {
        content: "X";
        width: calc(1.3rem - 5px);
        height: calc(1.3rem - 4px);
        background-color: #ffffff;
        border-radius: 50%;
        position: absolute;
        top: calc(var(--space-unit) / 2);
        left: calc(var(--space-unit) / 2);
        transition: .2s;
        font-weight: 800;
        text-align: center;
        padding-top: calc(var(--space-unit) * .5);
        padding-right: calc(var(--space-unit) * .2);
        color: var(--gray-5);
      }

      .toggle-input:checked + .form-check-label {
        background-color: var(--black);
      }

      .toggle-input:checked + .form-check-label:before {
        content: 'X';
        left: 54%;
        padding-right: 0;
        color: var(--black);
      }

      span,
      .toggle-label {
        font-weight: 400;
        line-height: 1.2;
      }
    }

    .toggle-error-mode {
      .form-check-label {
        background-color: var(--color-bg-error);
      }

      .form-check-label:before {
        content: "!";
        color: var(--color-error);
      }
    }

    .step-pagination {
      width: .6rem;
      height: .6rem;
      margin: .2rem;
      border-radius: 50%;
      border: 1px solid var(--black);
    }

    .mobile-description {
      color: var(--white);
      margin: var(--space-xs) 0;
      font-size: 1.3rem;
    }

    .left-block {
      background-size: cover;

      @include breakpoint-down-m {
        height: 20%;
      }

      .content-block {
        .fa-arrow-left {
          color: var(--white);
        }

        .logo {
          margin: 0 auto var(--space-l);

          img {
            width: 65%;
          }
        }

        ul {
          padding: 0;

          li {
            display: flex;
            justify-content: end;

            span {
              color: #ff007a;
            }

            p {
              font-weight: 400;
            }
          }
        }
      }
    }

    .left-block:not(.bg-image-moodboard):not(.bg-image-newsletter-survey) {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .5);
    }

    .bg-image-inscription {
      background-image: url('https://cdn.tag-walk.com/custom,fit_cover,width_1300/auth-form-inscription-mode-description-bg.png');
    }

    .bg-image-connection {
      background-image: url('https://cdn.tag-walk.com/custom,fit_cover,width_1300/auth-form-connection-mode-description-bg.png');
    }

    .bg-image-newsletter-survey {
      background-image: url('https://cdn.tag-walk.com/custom,fit_cover,width_1300/auth-form-inscription-mode-newsletter-survey-description-bg.png');

      .logo {
        margin: 0 auto calc(var(--space-default) + 4px);

        img {
          width: 100%;
          margin-top: 4rem;
        }
      }
    }

    .inscription-mode-moodboard-title {
      font-family: var(--font-secondary);
      position: relative;
      bottom: 223px;
      color: var(--color-burgundy-title);
      font-size: calc(var(--text-base-size) * 1.7);
      font-weight: 800;
      text-transform: uppercase;
      text-align: center;
      line-height: 25px;
      margin: 0 calc(var(--space-unit) + 2px);

      @include breakpoint-down-s {
        position: unset;
      }
    }

    .bg-image-moodboard {
      background-image: url('https://cdn.tag-walk.com/custom,fit_cover,width_1300/auth-form-inscription-mode-moodboard-description-bg.png');
    }

    .right-block {
      height: 100%;
      position: relative;
      overflow-y: auto;

      @include breakpoint-down-m {
        border-radius: 5px;
        height: 70%;
      }

      .button-link {
        text-decoration: underline;
        display: block;
        margin: 0 auto;
        font-size: var(--text-base-size);
      }

      .third-party-connection {
        height: 2rem;

        hr {
          margin-right: 0;
          margin-left: 0;
        }

        p {
          text-transform: uppercase;
          margin: 0 var(--space-unit);
          padding: var(--space-unit) var(--space-unit) 0;
        }
      }

      .go-back-block {
        @include breakpoint-up-s {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .dots {
          padding-left: 0;
          list-style: none;
        }

        .active {
          background-color: var(--black);;
        }

        .go-back {
          display: block;
          margin: 0 auto var(--space-default);
          text-decoration: underline;
        }
      }

      label {
        color: var(--black);
        font-size: .7rem;
      }

      .action-toggle-checkbox {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 3;
        cursor: pointer;
      }

      .toggle-page {
        overflow: hidden;
        z-index: 2;
      }

      .action-toggle {
        position: relative;
        border-radius: 20px;
        height: 36px;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 1;
        margin-bottom: calc(var(--space-unit) * 6);
      }

      .layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--gray-3);
        transition: 0.3s ease all;
        z-index: 1;
      }

      .toggle-page:before,
      .toggle-page:after,
      .toggle-page span {
        position: absolute;
        top: 6%;
        width: 50%;
        font-size: var(--text-base-size);
        font-weight: bold;
        color: var(--black);
        text-align: center;
        line-height: 1;
        padding: var(--space-default);
        border-radius: 20px;
        transition: 0.3s ease all;
        text-transform: uppercase;
      }

      .toggle-page:before {
        content: "";
        left: 2px;
        background-color: var(--white);
      }

      .toggle-page .sign-in {
        font-weight: 400;
        top: -4%;
        color: var(--black);
        left: 0%;

        @include breakpoint-down-m {
          top: -8%;
        }
      }

      .toggle-page .create-account {
        top: -4%;
        font-weight: 400;
        color: var(--white);
        left: 50%;

        @include breakpoint-down-m {
          top: -8%;
        }
      }

      .toggle-page:after {
        right: var(--space-unit);
        color: var(--gray-6);
      }

      .action-toggle-checkbox:checked + .toggle-page .sign-in {
        color: var(--white);
      }

      .action-toggle-checkbox:checked + .toggle-page .create-account {
        color: var(--black);
      }

      .action-toggle-checkbox:checked + .toggle-page:before {
        left: 49.3%;
      }

      .action-toggle-checkbox:checked + .toggle-page:after {
        color: var(--gray-6);
      }

      .form-section {
        width: 80%;
        margin: 0 auto;

        form {
          .form-group {
            position: relative;
            display: flex;
            align-items: center;
            margin: var(--space-xs) 0;

            .form-control {
              display: block;
              width: 100%;
              height: calc(1.5em + 0.75rem + 2px);
              padding: .375rem .75rem;
              font-size: calc(var(--text-base-size) * 1.125);
              font-weight: 400;
              line-height: 1.5;
              color: var(--black);
              background-color: var(--white);
              background-clip: padding-box;
              border: 1px solid var(--gray-3);
              border-radius: 0;
              transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }

            &.email {
              display: flex;
              align-items: center;
              margin-top: calc(var(--space-unit) * 6);
            }

            &.fashion-industry {
              #fashionIndustry {
                width: 26px;
                height: unset;
              }

              label {
                font-size: calc(var(--text-base-size) * 1.14);
                padding: var(--space-unit) calc(var(--space-default) - 1px) 0 var(--space-unit);
              }
            }
          }

          .forgot-password {
            padding: var(--space-xs);
          }
        }

        button[type="submit"] {
          @include text-label-large;
          font-size: calc(var(--text-base-size) * 1.125);
          display: block;
          margin: calc(var(--space-unit) * 6) 0 var(--space-default);
          padding: calc(var(--space-unit) + 2px) var(--space-s) calc(var(--space-unit) - 1px);
          letter-spacing: revert;

          .fa-angle-right {
            margin-left: var(--space-xs);
          }

          .spinner {
            margin-right: var(--space-xs);
            height: 13px;
            width: 13px;
          }
        }

        .birthdate-container {
          position: relative;
        }

        .placeholder-text {
          font-size: calc(var(--text-base-size) * 1.2);
          position: absolute;
          top: var(--space-xxs);
          left: calc(var(--space-s));
          pointer-events: none;
          color: var(--gray-7);
          background-color: var(--white);
        }

        .unset .placeholder-text {
          opacity: 0;
        }

        @include breakpoint-down-m {
          width: 85%;
        }

        .alert-danger {
          border-radius: .6rem;
        }

        .form-control[type="checkbox"] {
          height: unset;
        }

        #birthdate {
          font-size: var(--text-base-size);
          -webkit-appearance: initial;
          min-width: 96%;
        }

        #gender, #jobTitle, #country {
          font-size: calc(var(--text-base-size) * 1.2);

          .css-319lph-ValueContainer {
            padding: 0 calc(var(--space-s) - 2px);
          }
        }

        .display-password {
          position: absolute;
          top: 25%;
          right: 7%;
        }
      }

      hr {
        width: 50%;
        border-top: .5px solid var(--gray-8);
      }

      .wrapper {
        display: flex;
        align-items: center;
        margin: 0 auto var(--space-xs);
        padding: 0;
        border: 1px solid var(--black);
        width: fit-content;

        span {
          padding: 0 var(--space-default);
        }

        p {
          font-size: calc(var(--text-base-size) * 1.125);
          margin-bottom: 0;
          padding: var(--space-unit) var(--space-default) 0 0;
        }
      }

      .fa-stack {
        left: 0;
        font-size: 1rem;
      }

      .fa-facebook {
        font-weight: 400;
        top: 0.1rem;
        right: 0.12rem;
        position: relative;
      }

      .fa-apple {
        top: 0.08rem;
        position: relative;
      }

      .terms-conditions,
      .terms-conditions a,
      .is-survey-rewards {
        font-size: calc(var(--text-base-size) * .9);
        color: var(--gray-7);

        u {
          font-weight: 400;
          color: var(--black);
        }
      }

      .terms-conditions {
        text-align: center;
        margin: calc(var(--space-unit) * 6) auto 0;
        width: 80%;
        padding-bottom: var(--space-xs);
      }
    }
  }

  #root {
    .auth-page-container {
      width: 100%;
      position: absolute;
      top: 0;

      .right-block {
        @include breakpoint-up-s {
          width: 50%;
        }

        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;

        .logo {
          @include breakpoint-down-m {
            display: none !important;
          }
        }

        .form-section {
          width: 53%;

          @include breakpoint-up-s {
            margin: var(--space-xxl) auto 0;
          }

          @include breakpoint-down-m {
            margin-top: var(--space-m);
            width: 87%;
          }

          .logo {
            @include breakpoint-down-m {
              display: none;
            }

            img {
              width: 10%;
            }
          }

          form {
            display: block;
            margin: 0 auto;

            @include breakpoint-down-m {
              width: 100%;
            }
          }
        }

        .third-party-connection-container {
          width: 100%;
        }

        .link-signin-block {
          position: relative;
          bottom: 0;
        }
      }

      .left-block {
        padding: 0 calc(var(--space-default) - 1px) var(--space-xs);

        .fa-arrow-left {
          margin-top: var(--space-l);
        }

        @include breakpoint-up-s {
          height: 100%;
          width: 50%;
        }

        .logo {
          img {
            margin-top: var(--space-xs);
          }
        }
      }
    }
  }
}

.login-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: calc(var(--z-index-backdrop) + 1);
}
