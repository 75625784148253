@import '../defaults/typography';

.tds {
  .single-input-form {
    border: 1px solid var(--black);
    border-radius: 24px;
    background-color: var(--white);

    display: flex;
    padding: 2px;

    input {
      width: 100%;
      padding: 0 calc(var(--space-default) * 1.5) 0 var(--space-default);

      background-color: var(--white);
      border: none;
      border-radius: 24px;

      &:focus {
        outline: none;
      }

      &:autofill {
        box-shadow: inset 0 0 0 100px var(--white); // Hack to override browsers default style when autofilling
      }
    }
  }

  .newsletter-form {
    form {
      margin-bottom: var(--space-s);
      max-width: 100%;
    }

    p {
      @include text-label-small;
      color: var(--gray-5);
      padding: 0 var(--space-default);
    }
  }

  .select {
    position: relative;

    select {
      appearance: none;
      -webkit-appearance: none;
      cursor: pointer;

      width: 100%;
      padding: var(--space-xxs) var(--space-s);

      background-color: var(--white);
      border: 1px solid var(--gray-5);
      border-radius: 20px;

      option:disabled {
        color: var(--color-placeholder);
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: var(--space-s);
      transform: translateY(-50%);

      width: 16px;
      height: 16px;

      background: currentColor;
      mask-size: cover;
      -webkit-mask-size: cover;
      pointer-events: none;

      mask-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      -webkit-mask-image: url('data:image/svg+xml;charset=utf-8,<svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 9L12 15L18 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }

  form {
    .input-group {
      label {
        @include text-label-large;

        margin-bottom: calc(var(--space-default) * .375);
      }

      input {
        display: block;
        width: 100%;
        padding: var(--space-unit) calc(var(--space-default) * .75);

        border-radius: 24px;
        border: 1px solid var(--gray-5);
      }
    }
  }
}
