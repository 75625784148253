.carousel {
  overflow: hidden;
}

.carousel-content {
  display: flex;
  margin-bottom: var(--space-default);

  transition: all 250ms linear;
  scrollbar-width: none;

  & > * {
    flex: 100% 1 0;
  }
}

.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: var(--space-xxs);

  .carousel-dot {
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: var(--gray-3);
    cursor: pointer;

    &:not(.carousel-dot--current):hover {
      background-color: var(--gray-4);
    }

    &--current {
      background-color: var(--black);
    }
  }
}
