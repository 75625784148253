@import '../defaults/typography';
@import '../defaults/mixins';

.tds {
  .ribbon {
    @include text-title-medium;
    text-align: center;
    padding: var(--space-unit);

    @include breakpoint-down-s {
      @include text-label-medium;
      padding: var(--space-xxs) var(--space-unit) calc(var(--space-unit) / 2);
    }
  }

  .ribbon-black {
    @extend .ribbon;
    background-color: var(--black);
    color: var(--white);
  }
}
