@import 'mixins';

/*
 * Main GRID SYSTEM
 * Use this one to create complex layouts, where columns don't always have the same size.
 * To use it, first create a row with the .row class.
 * Then, add columns in your row by using .col-x-of-y utility classes where x-of-y is the proportion of your column.
 */

.tds {
  .row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr auto;
    column-gap: calc(var(--space-default) * 1.5);
  }

  .col-1-of-1 {
    grid-column: auto / span 12;
  }

  .col-1-of-2 {
    grid-column: auto / span 6;
  }

  .col-1-of-3 {
    grid-column: auto / span 4;
  }

  .col-2-of-3 {
    grid-column: auto / span 8;
  }

  .col-2-of-12 {
    grid-column: auto / span 2;
  }

  .col-3-of-12 {
    grid-column: auto / span 3;
  }

  .col-6-of-12 {
    grid-column: auto / span 6;
  }

  .col-9-of-12 {
    grid-column: auto / span 9;
  }

  .col-10-of-12 {
    grid-column: auto / span 10;
  }

  @include breakpoint-down-xs {
    *[class*=col-] {
      grid-column: auto / span 12;
    }
  }
}

/*
 * SIMPLE GRID SYSTEM
 * Use this one to easily create a layout with same size columns.
 * To use it, use the .grid-n utility classes where n is the number of columns.
 */

@mixin simple-grid($columns) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  gap: calc(var(--space-default) * 1.5);

  @include breakpoint-down-xs {
    gap: var(--space-xs);
  }
}

.tds {
  .grid-8 {
    @include simple-grid(8);

    @include breakpoint-down-s {
      @include simple-grid(4);
    }
  }

  .grid-6 {
    @include simple-grid(6);
  }

  .grid-5 {
    @include simple-grid(5);

    @include breakpoint-down-xs {
      @include simple-grid(2);
    }
  }

  .grid-4 {
    @include simple-grid(4);

    @include breakpoint-down-s {
      @include simple-grid(2);
    }
  }

  .grid-3 {
    @include simple-grid(3);
  }

  .grid-2 {
    @include simple-grid(2);
  }
}

/*
 * FLEX UTILITIES
 * Use this one to quickly create layouts with flexbox.
 */

.tds {
  .flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-align-end {
    display: flex;
    align-items: end;
  }

  .flex-center {
    @extend .flex;
    justify-content: center;
    align-items: center;
  }

  .flex-end {
    @extend .flex;
    justify-content: end;
    align-items: center;
  }

  .flex-space-between {
    @extend .flex;
    justify-content: space-between;
    align-items: center;
  }
}
