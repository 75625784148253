:root {
  --black: #000000;
  --gray-10: #2E3132;
  --gray-9: #444748;
  --gray-8: #5C5F5F;
  --gray-7: #747878;
  --gray-6: #8E9192;
  --gray-5: #A9ACAC;
  --gray-4: #C4C7C7;
  --gray-3: #E1E3E3;
  --gray-2: #EFF1F1;
  --gray-1: #F8FAFA;
  --white: white;
  --beige: #F7F5F1;

  --color-primary-1: #383B97;
  --color-primary-2: #5054B0;
  --color-primary-3: #696ECB;
  --color-primary-4: #8388E7;

  --color-error: #DE3730;
  --color-bg-error: #F8D7DA;
  --color-success: #14AE56;
  --color-bg-success: #DBFAE9;
  --color-transparent: transparent;

  --color-dashboard-table-header: #CAD1FF;

  --color-text: var(--black);
  --color-placeholder: var(--gray-5);
  --color-border: var(--gray-3);

  --color-attention: #DE3730;
  --color-burgundy-title: #56130D;

  --black-overlay: rgba(33, 33, 33, .2);
}
