@import 'mixins';

@font-face {
  font-family: 'Gill Sans';
  src: url('../../fonts/GillSans/309507_0_0.eot');
  src: url('../../fonts/GillSans/309507_0_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/GillSans/309507_0_0.woff2') format('woff2'),
    url('../../fonts/GillSans/309507_0_0.woff') format('woff'),
    url('../../fonts/GillSans/309507_0_0.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('../../fonts/GillSans/309507_1_0.eot');
  src: url('../../fonts/GillSans/309507_1_0.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/GillSans/309507_1_0.woff2') format('woff2'),
    url('../../fonts/GillSans/309507_1_0.woff') format('woff'),
    url('../../fonts/GillSans/309507_1_0.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}

:root {
  --font-primary: 'Gill Sans', Calibri, sans-serif;
  --font-secondary: 'Playfair Display', serif;

  --text-base-size: 1em;
}

@mixin text-display-large {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 3.5625);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -.25px;
  line-height: 1.12;
}

@mixin text-display-medium {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 2.8125);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -.25px;
  line-height: 1.16;
}

@mixin text-display-small {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 2.25);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  line-height: 1.22;
}

@mixin text-headline-large {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 2);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
}

@mixin text-headline-medium {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 1.75);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  line-height: 1.28;
}

@mixin text-headline-small {
  font-family: var(--font-secondary);
  font-size: calc(var(--text-base-size) * 1.5);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'case' on;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
}

@mixin text-title-large {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * 1.375);
  font-style: normal;
  font-weight: 400;
  line-height: 1.27;
}

@mixin text-title-semi-medium {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * 1.125);
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
}

@mixin text-title-medium {
  font-family: var(--font-primary);
  font-size: var(--text-base-size);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .15px;
}

@mixin text-title-small {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .9375);
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: .1px;
}

@mixin text-label-large {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .875);
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: .1px;
}

@mixin text-label-medium {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .75);
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .5px;
}

@mixin text-label-small {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .6875);
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: .5px;
}

@mixin text-body-large {
  font-family: var(--font-primary);
  font-size: var(--text-base-size);
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: .5px;
}

@mixin text-body-medium {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .875);
  font-style: normal;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: .25px;
}

@mixin text-body-small {
  font-family: var(--font-primary);
  font-size: calc(var(--text-base-size) * .75);
  font-style: normal;
  font-weight: 300;
  line-height: 1.33;
}

body:not(.tds) {
  font-size: 1rem;
}

.tds {
  body {
    @include text-body-large;
    color: var(--color-text);
  }

  h1, h2, h3, h4, h5, h6, form legend {
    color: var(--color-text-heading);
    margin-bottom: var(--space-xxs);
  }

  h1, h2, h3, h4, h5, h6 {
    a {
      text-decoration: none;
    }
  }

  h1 {
    @include text-display-large;
  }

  h2 {
    @include text-display-medium;

    @include breakpoint-down-xs {
      @include text-headline-medium;
    }
  }

  h3 {
    @include text-display-small;

    @include breakpoint-down-xs {
      @include text-headline-small;
    }
  }

  h4 {
    @include text-headline-large;
  }

  h5 {
    @include text-headline-medium;
  }

  h6 {
    @include text-headline-small;
  }

  p, ul {
    @include text-body-large;
  }

  ul {
    padding-left: var(--space-default);
  }

  .metadata {
    @include text-label-medium;
    font-style: italic;
    color: var(--gray-7);
  }

  .text-right {
    text-align: right !important;
  }
}
