.tds {
  .badge {
    text-align: center;
    position: absolute;
    right: calc(var(--space-unit) + 2px);
    top: calc(var(--space-unit) + 2px);
    background-color: var(--color-attention);
    padding: calc(var(--space-unit) * .8) calc(var(--space-unit) * 2) 0;
    color: var(--white);
    text-transform: uppercase;
    border-radius: calc(var(--space-unit) * 6);
    min-width: 30%;

    @include text-label-small;

    @include breakpoint-up-s {
      font-size: calc(var(--text-base-size) * .5);
    }
  }
}
