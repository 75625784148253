@import '../defaults/typography.scss';

body:has(.p3-advanced-paywall) {
  .tds div.footer {
    margin-top: 0;
  }
}

.tds {
  div.footer {
    display: grid;
    grid-template-columns: 40% repeat(3, auto);
    gap: var(--space-m);
    justify-content: space-between;

    background-color: var(--black);
    color: var(--white);

    padding: calc(var(--space-default) * 4) var(--space-l);
    margin-top: var(--space-xxl);

    @include breakpoint-down-s {
      padding: var(--space-l) var(--space-m);
      grid-template-columns: repeat(2, auto);

      & > div.ad, & > div:nth-child(5) {
        grid-area: auto / span 2;
      }
    }

    @include breakpoint-down-xs {
      padding: calc(var(--space-default) * 2.25) var(--space-default);
      gap: var(--space-default);
    }

    h4 {
      margin-bottom: var(--space-default);

      @include breakpoint-down-xs {
        @include text-headline-small;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      @include text-title-medium;
      text-transform: uppercase;
      line-height: 1.8;

      a {
        text-decoration: none;
      }
    }

    .ad img {
      border: 8px solid var(--white);
    }

    .stores, .socials {
      a {
        display: inline-block;

        &:not(:last-child) {
          margin-right: var(--space-default);
        }
      }
    }

    .stores img {
      height: 38px;
    }

    .socials img {
      height: 28px;
    }
  }
}
