.slideshow {
  display: flex;
  flex-direction: column;

  .slideshow-slides {
    margin-bottom: calc(var(--space-default) * 2.25);
  }

  .slide:not(.slide--active) {
    display: none;
  }

  .slideshow-dots {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--space-default) * 1.5);
  }

  .slideshow-dot {
    display: block;
    width: 12px;
    height: 12px;

    background-color: var(--gray-5);
    border-radius: 50%;

    cursor: pointer;
  }

  .slideshow-dot--active {
    background-color: var(--gray-10);
  }
}
