@import '../defaults/typography';

.tds {
  .box {
    border: 1px solid var(--black);
    padding: calc(var(--space-default) * 1.5);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint-down-xs {
      padding: var(--space-s);
    }
  }

  .filled-box {
    @extend .box;

    background-color: var(--black);
    color: var(--white);

    a {
      color: inherit;
    }
  }

  .newsletter-box {
    @extend .filled-box;

    h6 {
      text-align: center;
      margin-bottom: var(--space-xxs);
    }

    .subtitle {
      @include text-body-small;
      text-transform: uppercase;
      text-align: center;
      color: var(--gray-4);

      margin-top: 0;
      margin-bottom: var(--space-s);
    }
  }
}
