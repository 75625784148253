.tds {
  .section {
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
  }

  .titled-section {
    @extend .section;
    position: relative;

    h2 {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding-right: calc(var(--space-default) * 1.5);
      transform: translateY(-64%);

      background-color: var(--white);
    }
  }
}
