.tds .spinner {
  border: 3px solid var(--gray-2);
  border-top: 3px solid var(--black);
  border-radius: 50%;

  display: inline-block;
  width: 24px;
  height: 24px;

  animation: spin 1.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
