@import '../defaults/typography';

.tds {
  .os-device-banner {
    background-color: var(--gray-1);
    @include text-body-small;

    position: relative;
    padding: var(--space-s);

    .os-device-content {
      display: flex;
      justify-content: center;
    }

    .os-device-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-xxs);
    }

    .os-device-badge img {
      max-height: 50px;
      width: auto;
    }

    .os-device-close {
      position: absolute;
      top: var(--space-s);
      right: var(--space-s);

      i::before {
        font-size: calc(var(--text-base-size) * 2);
      }
    }
  }
}
