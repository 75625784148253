.tds {
  .tds-dropdown-toggle {
    position: relative;

    * {
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .tds-dropdown {
    position: absolute;
    top: calc(100% + var(--space-xs));
    left: 0;
    min-width: 182px;
    z-index: calc(var(--z-index-backdrop) + 1);
    padding: var(--space-xxs);

    display: none;
    background-color: var(--white);
    border-radius: 12px;

    & > * {
      display: block;
      padding: var(--space-xs) var(--space-s);

      border-radius: var(--space-xxs);

      &:hover {
        background-color: var(--gray-2);
      }
    }

    & > a {
      text-decoration: none;
    }

    &.tds-dropdown--open {
      display: block;
    }
  }
}
