@import '../defaults/typography';

.tds {
  .btn {
    background-color: var(--black);
    border: none;
    border-radius: 24px;

    @include text-label-large;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;

    padding: var(--space-xs) var(--space-m) var(--space-xxs);

    &:hover {
      color: var(--white);
    }
  }

  .btn-secondary {
    @extend .btn;
    background-color: var(--white);
    color: var(--black);
    border: 1px solid var(--black);

    &:hover {
      color: var(--black);
    }
  }

  .btn-compact {
    @extend .btn;
    padding: var(--space-unit) calc(var(--space-default) * 1.125) calc(var(--space-unit) / 2);
  }

  .btn-danger {
    background-color: var(--color-error);
    border: none;
    border-radius: 24px;

    @include text-label-large;
    color: var(--white);
    text-transform: uppercase;
    cursor: pointer;

    padding: var(--space-xs) var(--space-m) var(--space-xxs);
  }

  .btn-link {
    cursor: pointer;
    border: none;
    text-decoration: underline;
    background-color: var(--color-transparent);

    &:focus {
      outline: none;
    }
  }

  a.btn {
    display: inline-block;
    text-decoration: none;
  }
}
