@import '../defaults/mixins';
@import '../defaults/typography';

.tds {
  #header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    padding: calc(var(--space-unit) * 6) 0;

    @include breakpoint-down-xs {
      row-gap: var(--space-xs);
      padding: var(--space-s)
    }

    i {
      font-size: calc(var(--text-base-size) * 1.5);
    }
  }

  #header .locale-selector {
    padding-left: var(--space-m);
    justify-self: start;
    @include text-label-large;

    @include breakpoint-down-xs {
      grid-row: 2;
      grid-column: 1 / span 3;

      display: none;
      width: 100%;
      padding: var(--space-s) 0;

      border-top: 1px solid var(--black);
    }

    .locale-selector--current {
      display: flex;
      align-items: center;

      @include breakpoint-down-xs {
        display: none;
      }

      i {
        margin-right: var(--space-s);
      }

      span {
        margin: .3em 0 0 var(--space-s);
      }
    }

    .locale-selector--choices {
      display: none;
      align-items: center;
      gap: var(--space-s);
      color: var(--gray-6);

      @include breakpoint-down-xs {
        display: flex;
      }

      a {
        text-decoration: none;
        margin-top: .3em;
        color: var(--gray-6);

        &.active, &:hover {
          color: var(--color-text);
        }
      }
    }

    &:hover {
      .locale-selector--current {
        display: none;
      }

      .locale-selector--choices {
        display: flex;
      }
    }
  }

  #header .menu-trigger {
    display: none;

    @include breakpoint-down-xs {
      display: flex;
    }

    .open-menu {
      display: flex;
      align-items: center;

      span {
        @include text-label-medium;
        margin: 3px 0 0 var(--space-xxs);
      }
    }

    .close-menu {
      display: none;
    }
  }

  #header #logo {
    justify-self: center;

    img {
      height: 50px;
      width: auto;
    }
  }

  #promoBand {
    padding: var(--space-unit);

    p {
      @include text-headline-small;
      font-size: calc(var(--text-base-size) * .7);
    }

    @include breakpoint-up-s {
      display: flex;
      justify-content: center;
    }

    @include breakpoint-down-s {
      text-align: center;
    }

    .link-text {
      text-decoration: underline;
      margin-left: var(--space-unit);
    }
  }

  #header #account-menu {
    justify-self: end;
    padding-right: var(--space-m);

    @include breakpoint-down-xs {
      padding-right: 0;
    }

    .account-menu--logged-in {
      display: none;
      position: relative;

      button {
        background-color: transparent;
        border: none;

        padding: 0;
        position: relative;
        z-index: calc(var(--z-index-backdrop) + 3);

        color: var(--black);

        &:focus {
          outline: none;
        }
      }

      .tds-dropdown {
        left: auto;
        right: calc(var(--space-xs) * -1);
        top: calc(var(--space-xs) * -1);
        z-index: calc(var(--z-index-backdrop) + 2);

        padding-top: calc(var(--space-default) + var(--text-base-size) * 1.5);

        .separator {
          border-radius: 0;
          margin: var(--space-xs);
          padding: 0;
        }

        #identifierBlock {
          p {
            @include text-body-medium;
            color: var(--gray-4);
          }

          i {
            font-size: var(--text-base-size);
            padding-bottom: calc(var(--space-default) / 6);
          }
        }

        #identifierBlock:hover {
          background-color: transparent;
        }
      }
    }

    .account-menu--logged-out {
      display: none;
    }

    i {
      vertical-align: middle;

      &.iconoir-nav-arrow-down {
        font-size: var(--text-base-size);
      }
    }
  }

  #menu {
    padding: 0 calc(var(--space-default) * 12.5);
    position: relative;

    @include text-label-large;
    text-align: center;
    text-transform: uppercase;

    background-color: var(--black);

    @include breakpoint-down-m {
      padding: 0 var(--space-m);
    }

    @include breakpoint-down-xs {
      position: fixed;
      top: 160px;
      left: -100%;
      height: 100%;

      width: 100%;
      z-index: var(--z-index-backdrop);

      background-color: var(--black);
      padding: var(--space-m) var(--space-s);
      border: none;
      color: var(--white);
    }

    .sub-menu-trigger {
      @include breakpoint-up-xs {
        position: relative;
      }

      .sub-menu {
        @include breakpoint-up-xs {
          position: absolute;
        }
        left: 0;
      }
    }

    ul {
      padding: 0;
      list-style-type: none;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint-down-xs {
        display: initial;
        text-align: left;
      }

      li {
        @include breakpoint-down-xs {
          margin-bottom: var(--space-default);
        }

        .nav-item {
          font-weight: 400;
          display: block;
          margin: var(--space-default) 0 calc(var(--space-default) - 6px);
          position: relative;
          color: var(--gray-4);
          cursor: pointer;

          @include breakpoint-down-xs {
            display: initial;
          }

          &:hover {
            border-color: var(--white);
            color: var(--white);
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 0;
            bottom: -10px;
            height: 1px;
            background-color: var(--white);
            transition: width .3s ease;
          }

          &:hover::after {
            width: 100%;
          }
        }

        .dropdown-nav-item {
          display: flex;
        }

        a {
          text-decoration: none;
        }

        .sub-menu {
          width: 300px;
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: calc(var(--z-index-backdrop) + 2);

          @include breakpoint-down-xs {
            position: static;
            display: block;

            padding-top: 0;
            max-height: 0;
            overflow: hidden;

            border: none;
            transition: max-height .3s ease-in-out;

            background-color: var(--black);
            color: var(--white);
          }

          #insightsCount {
            display: none;
            padding-top: calc(var(--space-unit) * 0.5);
            align-items: center;
            justify-content: center;
            background-color: var(--color-error);
            color: var(--white);
            border-radius: 50%;
            font-size: calc(var(--text-base-size) * .875);
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: calc(var(--space-unit) * 3.5);
            left: calc(var(--space-default) * 2.5);
          }

          ul {
            padding-top: var(--space-s);
            display: inherit;
            text-align: left;

            @include breakpoint-down-xs {
              padding: 0 var(--space-s);
            }

            a {
              display: inline-block;
              font-size: var(--text-base-size);
              text-transform: capitalize;
              font-weight: 200;
              margin: var(--space-unit) 0;
              color: var(--gray-4);

              @include breakpoint-down-xs {
                display: initial;
              }

              &:hover {
                color: var(--white);
              }
            }
          }
        }

        &.sub-menu-trigger:hover {
          .sub-menu {
            @include breakpoint-down-xs {
              max-height: 200px;
              padding-top: var(--space-default);
            }
          }
        }
      }
    }
  }
}

body.menu--opened {
  @include breakpoint-down-xs {
    position: fixed;
    inset: 0;
    overflow: hidden;

    #header .locale-selector {
      display: initial;
    }

    #header .menu-trigger {
      .open-menu {
        display: none;
      }

      .close-menu {
        display: initial;
      }
    }

    #menu {
      left: 0;
    }
  }
}

html:not(.tds) {
  body #header {
    font-size: 1rem;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include breakpoint-up-xs {
  .sub-menu-background {
    background-color: var(--black);
    height: 0;
    width: 100%;
    position: absolute;
    opacity: 0;
    border-top: .5px solid var(--gray-10);
    z-index: calc(var(--z-index-backdrop) + 1);
  }
}

@include breakpoint-down-xs {
  .sub-menu-background {
    display: none;
    height: 0 !important;
  }
}
