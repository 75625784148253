.tds {
  .text-center {
    text-align: center;
  }

  .text-danger {
    color: var(--color-error);
  }

  .d-none {
    display: none;
  }

  .m-x-auto {
    margin-inline: auto;
    display: block;
  }
}
