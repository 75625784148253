:root {
  --space-unit: 4px;

  --space-xxs: calc(var(--space-unit) * 1.5);
  --space-xs: calc(var(--space-unit) * 2);
  --space-s: calc(var(--space-unit) * 3);

  --space-default: calc(var(--space-unit) * 4);

  --space-m: calc(var(--space-default) * 2);
  --space-l: calc(var(--space-default) * 3);
  --space-xl: calc(var(--space-default) * 4.5);
  --space-xxl: calc(var(--space-default) * 6);
}

.tds {
  .mb-s {
    margin-bottom: var(--space-s);
  }

  .mt-s {
    margin-top: var(--space-s);
  }

  .mr-s {
    margin-right: var(--space-s);
  }

  .mb-d {
    margin-bottom: var(--space-default);
  }
}
