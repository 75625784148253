@import '../defaults/typography';

.tds {
  .photo-card {
    position: relative;

    img {
      object-fit: cover;
    }

    .photo-card-content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      padding: var(--space-xxs) var(--space-s);

      background: linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0));
      color: var(--white);
    }
  }
}
