:root {
  --z-index-backdrop: 10;
}

.backdrop {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.00) 100%);
  backdrop-filter: blur(2px);

  display: block;
  position: fixed;
  inset: 0;
  z-index: var(--z-index-backdrop);
}

body.backdrop--visible {
  overflow: hidden;

  .backdrop {
    display: block;
  }
}
