$breakpoint-xs: 576px;
$breakpoint-s: 768px;
$breakpoint-m: 992px;
$breakpoint-l: 1200px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1600px;
$breakpoint-xxxl: 1920px;

@mixin breakpoint-up-xs {
  @media only screen and (min-width: $breakpoint-xs + 1) {
    @content;
  }
}

@mixin breakpoint-down-xs {
  @media only screen and (max-width: $breakpoint-xs) {
    @content;
  }
}

@mixin breakpoint-up-s {
  @media only screen and (min-width: $breakpoint-s + 1) {
    @content;
  }
}

@mixin breakpoint-down-s {
  @media only screen and (max-width: $breakpoint-s) {
    @content;
  }
}

@mixin breakpoint-up-m {
  @media only screen and (min-width: $breakpoint-m + 1) {
    @content;
  }
}

@mixin breakpoint-down-m {
  @media only screen and (max-width: $breakpoint-m) {
    @content;
  }
}

@mixin breakpoint-up-l {
  @media only screen and (min-width: $breakpoint-l + 1) {
    @content;
  }
}

@mixin breakpoint-down-l {
  @media only screen and (max-width: $breakpoint-l) {
    @content;
  }
}

@mixin breakpoint-up-xl {
  @media only screen and (min-width: $breakpoint-xl + 1) {
    @content;
  }
}

@mixin breakpoint-down-xl {
  @media only screen and (max-width: $breakpoint-xl) {
    @content;
  }
}

@mixin breakpoint-up-xxl {
  @media only screen and (min-width: $breakpoint-xxl + 1) {
    @content;
  }
}

@mixin breakpoint-down-xxl {
  @media only screen and (max-width: $breakpoint-xxl) {
    @content;
  }
}

@mixin breakpoint-up-xxxl {
  @media only screen and (min-width: $breakpoint-xxxl + 1) {
    @content;
  }
}

@mixin breakpoint-down-xxxl {
  @media only screen and (max-width: $breakpoint-xxxl) {
    @content;
  }
}


@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track-piece {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--gray-3);
  }
}
