.tds {
  .alert-error {
    background-color: var(--color-bg-error);
    color: var(--color-error);
    text-align: center;
    padding: var(--space-default);
    margin: var(--space-unit);
  }

  .alert-success {
    background-color: var(--color-bg-success);
    color: var(--color-success);
    text-align: center;
    padding: var(--space-default);
    margin: var(--space-unit);
  }

  .alert-neutral {
    background-color: var(--gray-2);
    color: var(--gray-9);
    text-align: center;
    padding: var(--space-default);
    margin: var(--space-unit);
  }
}
