@import '../defaults/typography';

.tds {
  .pill {
    background-color: var(--gray-2);
    @include text-label-small;
    color: var(--gray-7);

    padding: var(--space-xxs) var(--space-s) var(--space-unit);
    border-radius: 16px;
  }
}
