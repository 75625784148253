// Stolen from the amazing Josh W Comeau: https://www.joshwcomeau.com/css/custom-css-reset/

@import 'typography';

.tds {
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  hr {
    height: 1px;
    border: none;
    background-color: var(--color-border);
  }

  a {
    color: inherit;
  }

  input::placeholder {
    color: var(--color-placeholder);
    @include text-body-large;
  }
}
