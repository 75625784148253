.tds {
  .modal {
    background-color: var(--white);
    border-radius: 12px;

    z-index: calc(var(--z-index-backdrop) + 1);
    position: absolute;
    top: 50%;
    left: 50%;

    display: none;
    transform: translate(-50%, -50%);
    padding: calc(var(--space-default) * 1.5);

    width: auto; // .tds specific, here too override Bootstrap rules
    height: auto; // .tds specific, here too override Bootstrap rules

    h6, h5 {
      text-align: center;
    }

    &.modal--open {
      display: block;
    }

    .close-modal {
      position: absolute;
      top: var(--space-s);
      right: var(--space-s);

      font-size: calc(var(--text-base-size) * 1.5);
      cursor: pointer;
    }

    @include breakpoint-down-xs {
      width: 100%;
    }
  }
}
